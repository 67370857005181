const recipes = {
    title: 'Recipes (Sneak Peek)',
    tagLine: 'Here is one example of the many recipes that are included in the full online coaching program. These recipes are added to the end of your meal plan and provide you with an extensive description of the steps to create a delicious meal.',
    recipes: [
        {
            flipped: false,
            name: 'Super Shrimp Pokébowl',
            img: 'super_shrimp.jpg',
            pie: 'piechart_5.png',
            description: 'Powerful bowl of healthy vegetables and fruit combined with shrimp as major protein source and rice as a base',
            serving_size: '1',
            duration: '25',
            calories: '615.0',
            carbs: '78.5',
            fats: '12.0',
            proteins: '50.0',
            ingredients: [
                {
                    name: 'Rice',
                    amount: '75 g',
                    calories: '165',
                    carbs: '0',
                    fats: '3',
                    proteins: '35',
                },
                {
                    name: 'Shrimp',
                    amount: '150 g',
                    calories: '259',
                    carbs: '57',
                    fats: '1',
                    proteins: '6',
                },
                {
                    name: 'Mango',
                    amount: '50 g',
                    calories: '10',
                    carbs: '1.5',
                    fats: '0',
                    proteins: '1',
                },
                {
                    name: 'Bell Pepper',
                    amount: '50 g',
                    calories: '80',
                    carbs: '4',
                    fats: '7',
                    proteins: '1',
                },
                {
                    name: 'Cucumber',
                    amount: '50 g',
                    calories: '8',
                    carbs: '2',
                    fats: '0',
                    proteins: '0',
                },
                {
                    name: 'Avocado',
                    amount: '50 g',
                    calories: '30',
                    carbs: '7',
                    fats: '0',
                    proteins: '1',
                },
                {
                    name: 'Edamame',
                    amount: '50 g',
                    calories: '63',
                    carbs: '7',
                    fats: '1',
                    proteins: '6',
                },
                {
                    name: 'Garlic Powder (Optional)',
                    amount: '2 dashes',
                    calories: '',
                    carbs: '',
                    fats: '',
                    proteins: '',
                },
            ],
            steps: [
                'In a bowl, marinate the shrimps with garlic powder',
                'Set up a saucepan with water and bring to a boil, cook the rice and (or preferably use rice cooker) and add turmeric',
                'Cut the paprika, avocado, mango & cucumber into small cubes',
                'Set a pan on medium heat and cook the marinated shrimp until fully cooked through and slightly crispy',
                'Add all ingredients to a bowl, starting with the rice',
                'Enjoy your super shrimp pokebowl!',
            ]
        }
    ],
    calories: 'Calories',
    prepTime: 'Prep time',
    servingSize: 'Serving size',
    ingredients: 'Ingredients',
    frontButton: "LET'S MAKE THIS",
    backButton: 'BACK TO INGREDIENTS',
    fats: 'Fats',
    proteins: 'Proteins',
    carbs: 'Carbs',
    steps: 'Steps'
}
export default recipes;